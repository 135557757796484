"use client";

import ContainerIcon from "@/assets/container-icon.svg";
import Footer from "@/components/footer";
import InitAnalytics from "@/components/init-analytics";
import ResponsiveView from "@/components/responsive-view";
import {
  networkMap,
  NetworkType,
  networkType,
  PRIVY_APP_ID,
  PRIVY_CLIENT_ID,
} from "@/config";
import { isMobileDevice, setGlobalTheme } from "@/utils";
import OnlyClient from "@/utils/OnlyClient";
import { ThemeProvider, useTheme } from "@/utils/ThemeContext";
import { PrivyProvider } from "@privy-io/react-auth";
import { Feedback } from "@spheron/ui-library";
import SpheronLogo from "@spheron/ui-library/dist/assets/spheron-specific/spheron-logo.svg";
import MaintanenceIcon from "@spheron/ui-library/dist/assets/spheron-specific/maintainance.svg";
import { useEffect, useState } from "react";
import { defineChain } from "viem";

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useTheme();

  useEffect(() => {
    setGlobalTheme(theme);
  }, [theme]);

  return <>{children}</>;
};

export default function Template({ children }: { children: React.ReactNode }) {
  const [deviceType, setDeviceType] = useState<"mobile" | "desktop">("desktop");

  useEffect(() => {
    const checkDeviceType = () => {
      // if (typeof window !== "undefined") {
      const userAgent = navigator.userAgent;
      // const width = window.innerWidth;

      // if (isMobileDevice(userAgent) || width < 1024) {
      if (isMobileDevice(userAgent)) {
        setDeviceType("mobile");
      } else {
        setDeviceType("desktop");
      }
      // }
    };

    checkDeviceType();

    window.addEventListener("resize", checkDeviceType);

    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  const network = networkMap[networkType as NetworkType];
  const spheronDevnet = defineChain({
    id: network.chainId,
    name: network.chainName,
    network: network.chainName,
    nativeCurrency: {
      decimals: network.nativeCurrency.decimals,
      name: network.nativeCurrency.name,
      symbol: network.nativeCurrency.symbol,
    },
    rpcUrls: {
      default: {
        http: network.rpcUrls,
      },
    },
    blockExplorers: {
      default: {
        name: "Explorer",
        url: network.blockExplorerUrls[0],
      },
    },
  });

  return (
    <div>
      {/* <div className="w-full">
        <Feedback
          feedbackType="warning"
          icon={<MaintanenceIcon className="w-6 h-6" />}
          subTitle={
            <div className="text-[11px]">
              New deployments are temporarily closed due to maintenance; we’ll notify once reopened.{' '}
            </div>
          }
          title=""
          showClose={false}
          withShadow={false}
          position="center"
          size="compact"
        />
      </div> */}
      <PrivyProvider
        appId={PRIVY_APP_ID}
        clientId={PRIVY_CLIENT_ID}
        config={{
          appearance: {
            accentColor: "#6A6FF5",
            theme: "#FFFFFF",
            showWalletLoginFirst: false,
            logo: "https://www.spheron.network/_next/static/media/spheron-logo.67ba7270.svg?imwidth=640",
          },
          loginMethods: ["email", "google", "discord", "github"],
          fundingMethodConfig: {
            moonpay: {
              useSandbox: true,
            },
          },
          embeddedWallets: {
            createOnLogin: "users-without-wallets",
            requireUserPasswordOnCreate: false,
          },
          mfa: {
            noPromptOnMfaRequired: false,
          },
          supportedChains: [spheronDevnet],
        }}
      >
        <InitAnalytics />
        <ThemeProvider>
          <ThemeWrapper>
            {deviceType === "mobile" ? (
              <ResponsiveView
                logoImage={
                  <SpheronLogo className="w-20 h-20 text-logo-primary dark:text-dark-logo-primary" />
                }
                infoImage={
                  <ContainerIcon className="w-28 h-28 text-logo-primary dark:text-dark-logo-primary" />
                }
                infoText="Desktop view required to access Console App"
              />
            ) : (
              <>
                {children}
                <footer className="mt-[100px]">
                  <OnlyClient>
                    <Footer />
                  </OnlyClient>
                </footer>
              </>
            )}
          </ThemeWrapper>
        </ThemeProvider>
      </PrivyProvider>
    </div>
  );
}
