// // Old addresses
// export const TokenRegistry = "0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe";
// export const ProviderRegistry = "0xc51F366131924e477a65b05D9ca97333daa3385D";
// export const Escrow = "0x495d613910FdeB29E83d9fb4B25EbFC3ACc8ddc4";
// export const OrderRequest = "0xB4df88D433Ff0AFe0DF3e2aE39681C28D8F71937";
// export const ResourceManager = "0x217Bf67daee35A2e59334B5209069FED1F10883A";
// export const ComputeLease = "0x5b6bf1923F11E3a6A123ed162E11fbBe39F51161";
// export const Bid = "0xb32026402F12aAE59Ee37E60495A434C1a044E27";
// export const ResourceRegistryCPU = "0xd92F72Ffc27E5949F61d10012760B2A98b508c02";
// export const ResourceRegistryGPU = "0xe479b5DC6D59B37f62b428Aeb84dF84C33Ce356A";
// export const ResourceRegistryFactory = "0xcD488162aB95759c83eEaC26660c2146Aa7B0B6f";
// export const RewardManager = "0x90e83Bf679DdAF16d4C1a1AF38BeD29293BBfCDf";
// export const RewardToken = "0xA0c5ba7d9eccbE59000e8E67654CA5d867C6b401";

// // Token addresses
// export const USDT = "0x270d622149F46fFc72A9fD5A8cF92D1FdE5AD5E0";
// export const USDC = "0x809372ebc19017276f1b5AF5678c306513567118";
// export const DAI = "0x014E547FdD3a7625c6BEb5Af92F34F0960D29ad3";
// export const WETH = "0x55B1835d60Bb7CFcfB838E0963E58ad84Fd9694F";

// New Testnet Contract Addresses
export const AccessControlManager =
  "0x87D3440372293aCf9149552546F7141AAe05Be91";
export const TokenRegistry = "0x032666197A5d9329e717800FC90E8C951bA12290";
export const ProviderRegistry = "0x7fDE3748e8AE27CaF506A47601acAf3B6AEaC34f";
export const ProviderAttributeRegistry =
  "0x87B49A9b928b06AA5782cB7ec495da8c507CaADC";
export const FizzRegistry = "0x6057730B3B355A111061D63d2D7399D8d51aac58";
export const FizzAttributeRegistry =
  "0x24A05B480235Ccb450bf7Ce7e9F65072Ed732292";
export const Escrow = "0x3854Be113E7B1C7fD8CF4805dF6c7f30d1FA1bC6";
export const OrderRequest = "0x1fdf629E5A90eE4FAab1336a23c41A0Cab8CbA9d";
export const ResourceManager = "0x270d622149F46fFc72A9fD5A8cF92D1FdE5AD5E0";
export const ComputeLease = "0x9C0a16a41d2b0fFc14e6f20Da1368b1FD93bD7e5";
export const Bid = "0x27930D6ee40E8DB57c713f063f4a97dc37dC3ffb";
export const ResourceRegistryCPU = "0x67B8c2201Eef00d1a8193a2aF301312DD21a286B";
export const ResourceRegistryGPU = "0x2B73F279044347A3B6c9F122Ec6b479b6C2beAc9";
export const ResourceRegistryFactory =
  "0xbb132eB1b36D87ef7Ac63c95c05a8a56eCDac3fd";
export const ProviderRewardsManager =
  "0x6fdbbD18A7575C0125c56Be4AfDbd1392C2be118";
export const FizzRewardsManager = "0xa0b6c8A205fe0B6851BA4Cc2f91BBE530A670519";
export const RewardToken = "0xB28aA1E5074AE0222cf764e2934182775Ee7AB42";
export const FizzNodeRewardToken = "0xA2400EFe3d7a9E2281F32A66Df18CFFE651c69f3";

// New Testnet Token addresses
export const USDT = "0x96Ca5955F25648FB44C5D772BAdB4313000C66Fe";
export const USDC = "0x816413630c39780677E1584D4a6DbAFd76b161F7";
export const DAI = "0xcF5F08686dBE94B88b93FB2E8E5482A7f432C892";
export const WETH = "0xd74C08f8ffDF88C807367813Ad64a960618f4dcC";
export const CST = "0x8E5996A3963011Ca9dFa04E3CB773147F98C0e17";
export const SPON = "0x76e593392523243ACD38ceD87c2007F14483a86B";
