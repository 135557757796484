import {
  BASE_URL,
  networkType,
  NetworkType,
  SPHERON_RPC_URL,
  tokenMap,
} from "@/config";
import sdk from "@/libs/spheron-sdk";
import EscrowAbi from "@/contracts/abis/Escrow.json";
import TokenAbi from "@/contracts/abis/TestToken.json";
import { CST, Escrow } from "@/contracts/addresses";
import { getTokenDetails, waitForTransaction } from "@/utils";
import { trackUserEvent } from "@/utils/analytics";
import { NotificationType, showToastNotification } from "@/utils/notification";
import { ConnectedWallet } from "@privy-io/react-auth";
import { SpheronSDK, TransactionStatus } from "@spheron/protocol-sdk";
import { ethers } from "ethers";
import { encodeFunctionData, parseUnits } from "viem";
import { getLeaseDetails, getTenantLeases } from "./lease";
import { getLiveTokenPrice, getLiveTokenPriceSSR } from "./tokens";
import { addGasPrice, MAX_FEE_PER_GAS } from "@/utils/chain";

export const getUserData = async (
  providerAddress: string,
  tokenAddress: string
) => {
  return sdk.escrow.getUserBalance(providerAddress, tokenAddress);
};

export const getBillingData = async (tenantAddress: string) => {
  const data = await Promise.all(
    tokenMap[networkType as NetworkType]
      .filter((token) => token.symbol === "CST")
      .map(async (token) => {
        const { lockedBalance, unlockedBalance } = await getUserData(
          tenantAddress,
          token.address
        );
        console.log("token balance ", { lockedBalance, unlockedBalance });
        const tokenSymbol = getTokenDetails(
          token.address,
          (networkType as NetworkType) || "testnet"
        )?.symbol;
        if (tokenSymbol) {
          let livePriceResponse: any;
          if (typeof window !== "undefined") {
            livePriceResponse = await getLiveTokenPrice(tokenSymbol);
          } else {
            livePriceResponse = await getLiveTokenPriceSSR(tokenSymbol);
          }
          return {
            tokenDetails: token,
            lockedBalance,
            unlockedBalance,
            livePrice: livePriceResponse?.price,
          };
        }
        return {
          tokenDetails: token,
          lockedBalance,
          unlockedBalance,
          livePrice: null,
        };
      })
  );
  return data;
};

export const getComputeUsageData = async (tenantAddress: string) => {
  const { activeLeaseIds, allLeaseIds } = await getTenantLeases(tenantAddress);

  const allActiveLeasesDetails = await Promise.all(
    activeLeaseIds.map((lease: string) => getLeaseDetails(lease.toString()))
  );

  const computeHours = allActiveLeasesDetails.reduce((total, range) => {
    const start = range.startTime;
    const end = range.endTime;
    if (end === 0)
      return total + (Date.now() - start * 1000) / (1000 * 60 * 60);
    return total + (end * 1000 - start * 1000) / (1000 * 60 * 60);
  }, 0);

  return { activeLeaseIds, allLeaseIds, computeHours };
};

export const withdrawBalance = async (
  tokenAddress: string,
  amount: number,
  decimals: number,
  setWithdrawLoading: (loading: boolean) => void,
  setWithdrawStatus: (status: TransactionStatus) => void,
  setIsOpen: (isOpen: boolean) => void
) => {
  setWithdrawLoading(true);
  try {
    if (typeof window.ethereum !== "undefined") {
      const sdk = new SpheronSDK();
      return sdk.escrow.withdrawBalance({
        rewardWallet: "",
        tokenAddress,
        amount,
        decimals,
        onSuccessCallback() {
          setWithdrawLoading(false);
          setWithdrawStatus(TransactionStatus.SUCCESS);
          setIsOpen(false);
          trackUserEvent("Withdraw Funds Success");
          showToastNotification({
            title: "Success",
            message: "Balance Withdrawn Successfully",
            type: NotificationType.Success,
            timestamp: Date.now(),
          });
        },
        onFailureCallback(data) {
          setWithdrawLoading(false);
          setWithdrawStatus(TransactionStatus.FAILURE);
          trackUserEvent("Withdraw Funds Failed", {
            reason: "",
            error: JSON.stringify((data as any).message),
          });
          showToastNotification({
            title: "Error Occured",
            message: JSON.stringify((data as any).message),
            type: NotificationType.Error,
            timestamp: Date.now(),
          });
        },
      });
    } else {
      showToastNotification({
        title: "Error Occured",
        message: "MetaMask not detected",
        type: NotificationType.Error,
        timestamp: Date.now(),
      });
      trackUserEvent("Withdraw Funds Failed", {
        reason: "Metamask not detected",
      });
      setWithdrawStatus(TransactionStatus.FAILURE);
      setWithdrawLoading(false);
    }
  } catch (error) {
    console.error("Error in withdrawProviderEarnings:", error);
    showToastNotification({
      title: "Error Occured",
      message: JSON.stringify(error),
      type: NotificationType.Error,
      timestamp: Date.now(),
    });
    trackUserEvent("Withdraw Funds Failed", {
      reason: "",
      error: JSON.stringify(error),
    });
    setWithdrawStatus(TransactionStatus.FAILURE);
    setWithdrawLoading(false);
  }
};

export const depositBalance = async (
  tokenAddress: string,
  amount: number,
  decimals: number,
  setDepositLoading: (loading: boolean) => void,
  setDepositStatus: (status: TransactionStatus) => void,
  setIsOpen: (isOpen: boolean) => void
) => {
  setDepositLoading(true);
  try {
    if (typeof window.ethereum !== "undefined") {
      const sdk = new SpheronSDK();
      return sdk.escrow.depositBalance({
        rewardWallet: "",
        tokenAddress,
        amount,
        decimals,
        onSuccessCallback() {
          setDepositLoading(false);
          setDepositStatus(TransactionStatus.SUCCESS);
          setIsOpen(false);
          trackUserEvent("Deposit Funds Success");
          showToastNotification({
            title: "Success",
            message: "Tokens Deposited Successfully",
            type: NotificationType.Success,
            timestamp: Date.now(),
          });
        },
        onFailureCallback(data) {
          setDepositLoading(false);
          setDepositStatus(TransactionStatus.FAILURE);
          trackUserEvent("Deposit Funds Failed", {
            reason: "",
            error: JSON.stringify((data as any).message),
          });
          showToastNotification({
            title: "Error Occured",
            message: JSON.stringify((data as any).message),
            type: NotificationType.Error,
            timestamp: Date.now(),
          });
        },
      });
    } else {
      showToastNotification({
        title: "Error Occured",
        message: "MetaMask not detected",
        type: NotificationType.Error,
        timestamp: Date.now(),
      });
      trackUserEvent("Deposit Funds Failed", {
        reason: "Metamask not detected",
      });
      setDepositStatus(TransactionStatus.FAILURE);
      setDepositLoading(false);
    }
  } catch (error) {
    console.error("Error in withdrawProviderEarnings:", error);
    showToastNotification({
      title: "Error Occured",
      message: JSON.stringify(error),
      type: NotificationType.Error,
      timestamp: Date.now(),
    });
    trackUserEvent("Deposit Funds Failed", {
      reason: "",
      error: error,
    });
    setDepositStatus(TransactionStatus.FAILURE);
    setDepositLoading(false);
  }
};

export const depositBalancePrivy = async ({
  wallet,
  amount,
}: {
  amount: number;
  wallet: ConnectedWallet | null;
}) => {
  try {
    const provider = await wallet?.getEthereumProvider();
    const contractABI = EscrowAbi;
    const tokenABI = TokenAbi;
    const contractAddress = Escrow;

    const tokenDetails = {
      symbol: "CST",
      address: CST,
      decimal: 6,
    };

    const decimals = tokenDetails.decimal ?? 6;
    const tokenAddress = tokenDetails.address;

    const finalAmount = Number(amount.toString());
    const depositAmount = parseUnits(finalAmount.toFixed(decimals), decimals);

    // Approve the contract to spend the token
    const approveData = encodeFunctionData({
      abi: tokenABI,
      functionName: "approve",
      args: [contractAddress, depositAmount],
    });

    const approveTransactionRequest = {
      to: tokenAddress,
      data: approveData,
      from: wallet?.address,
      // maxPriorityFeePerGas: "0x0", // Set to 0 for no priority fee
      // maxFeePerGas: MAX_FEE_PER_GAS,
    };

    // const approveTxnRequestWithGasPrice = await addGasPrice(
    //   approveTransactionRequest,
    //   provider!
    // );

    // Send the approval transaction
    const approveTxHash = await provider?.request({
      method: "eth_sendTransaction",
      // params: [approveTxnRequestWithGasPrice],
      params: [approveTransactionRequest],
    });

    console.log("approval txn -> ", approveTxHash);

    // Wait for approval transaction to be confirmed
    await waitForTransaction(provider, approveTxHash);

    // Prepare the deposit transaction
    const depositData = encodeFunctionData({
      abi: contractABI,
      functionName: "deposit",
      args: [tokenAddress, depositAmount],
    });

    const depositTransactionRequest = {
      to: contractAddress,
      data: depositData,
      from: wallet?.address,
      // maxPriorityFeePerGas: "0x0", // Set to 0 for no priority fee
      // maxFeePerGas: MAX_FEE_PER_GAS,
    };

    // const depositTxnRequestWithGasPrice = await addGasPrice(
    //   depositTransactionRequest,
    //   provider!
    // );

    // Send the deposit transaction
    const depositTxHash = await provider?.request({
      method: "eth_sendTransaction",
      // params: [depositTxnRequestWithGasPrice],
      params: [depositTransactionRequest],
    });

    // Wait for deposit transaction to be confirmed
    const receipt = await waitForTransaction(provider, depositTxHash);
    console.log("Deposit balance successful -> ", receipt);
    return receipt;
  } catch (error) {
    console.error("Error balance deposit -> ", error);
    throw error;
  }
};

export const creditBonusTokens = async ({
  walletAddress,
  email,
}: {
  walletAddress: string;
  email: string;
}) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/bonus?wallet=${walletAddress}&email=${email}`
    );
    const json = await response.json();
    console.log("bonus response -> ", json);
    return json;
  } catch (error) {
    console.log("error crediting bonus tokens -> ", error);
    return { success: false };
  }
};

export const getETHToTransfer = (amount: number) => {
  if (process.env.VERCEL_ENV === "production") return "0.01";
  else return "0.0005";
  if (amount >= 15 && amount < 30) {
    return "0.01";
  } else if (amount >= 30 && amount < 50) {
    return "0.015";
  } else if (amount > 50) {
    return "0.02";
  }
  return "0.01";
};

export const getCSTWalletBalance = async (walletAddress: string) => {
  const provider = new ethers.JsonRpcProvider(SPHERON_RPC_URL);
  const tokenContract = new ethers.Contract(CST, TokenAbi, provider);
  const balance = await tokenContract.balanceOf(walletAddress);

  return balance;
};
