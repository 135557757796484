import { CST, DAI, USDC, USDT, WETH } from "@/contracts/addresses";
import USDTIcon from "@spheron/ui-library/dist/assets/crypto-social/Tether.svg?url";
import USDCIcon from "@spheron/ui-library/dist/assets/crypto-social/Usdc.svg?url";
import DaiIcon from "@spheron/ui-library/dist/assets/crypto-social/Dai.svg?url";
import WethIcon from "@spheron/ui-library/dist/assets/crypto-social/Weth.svg?url";

// Testnet URLs
export const SERVICE_API_URL = "https://api-v2.spheron.network";

export const SPHERON_RPC_URL =
  "https://summer-evocative-gas.base-sepolia.quiknode.pro/a73ba0ae6a2d4c16c1729c80a243f0a00e9f5737";
export const WEBSOCKET_PROVIDER_URL =
  "wss://summer-evocative-gas.base-sepolia.quiknode.pro/a73ba0ae6a2d4c16c1729c80a243f0a00e9f5737";

export const SPHERON_MODEL_BASE_URL = "https://api.spheron.ai/v1";

export const SPHERON_MODEL_CHAT_PROXY_URL =
  "https://api.spheron.ai/v1/chat/completions";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://console.spheron.network"
    : "http://localhost:3009";

export const PROXY_URL =
  process.env.NODE_ENV === "production"
    ? "https://proxy-provider.spheron.network"
    : "http://localhost:3040";

export const PROVIDER_PROXY_WS_URL =
  process.env.NODE_ENV === "production"
    ? "wss://proxy-provider.spheron.network"
    : "ws://localhost:3040";

export const PRIVY_APP_ID = process.env.NEXT_PUBLIC_PRIVY_APP_ID || "";
export const PRIVY_APP_SECRET = process.env.PRIVY_APP_SECRET || "";
export const PRIVY_CLIENT_ID = process.env.NEXT_PUBLIC_PRIVY_CLIENT_ID || "";

export interface IToken {
  id: number;
  name: string;
  symbol: string;
  decimal: number;
  address: string;
  logo: any;
  minDeposit?: number;
}

export interface INetwork {
  chainId: number;
  chainName: string;
  rpcUrls: string[];
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  blockExplorerUrls: string[];
}

export type NetworkType = "testnet" | "mainnet";

export const networkMap: Record<NetworkType, INetwork> = {
  // testnet: {
  //   chainId: 421614,
  //   chainName: "Arbitrum Sepolia",
  //   rpcUrls: ["https://sepolia-rollup.arbitrum.io/rpc"],
  //   nativeCurrency: {
  //     name: "Ethereum",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },
  //   blockExplorerUrls: ["https://sepolia.arbiscan.io/"],
  // },
  testnet: {
    chainId: 84532,
    chainName: "Base Sepolia",
    rpcUrls: [
      "https://summer-evocative-gas.base-sepolia.quiknode.pro/a73ba0ae6a2d4c16c1729c80a243f0a00e9f5737",
    ],
    nativeCurrency: {
      name: "Sepolia Ether",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://sepolia.basescan.org/"],
  },
  // TODO: NEED TO UPDATE WHEN MAINNET RELEASE
  mainnet: {
    chainId: 421614,
    chainName: "Arbitrum Sepolia",
    rpcUrls: ["https://sepolia-rollup.arbitrum.io/rpc"],
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://sepolia.arbiscan.io/"],
  },
};

// TODO: getAllTokens contract call
export const tokenMap: Record<NetworkType, IToken[]> = {
  testnet: [
    {
      id: 1,
      name: "Tether USD Test Token",
      symbol: "USDT",
      decimal: 6,
      address: USDT,
      logo: USDTIcon,
      minDeposit: 15,
    },
    {
      id: 2,
      name: "DAI Test Token",
      symbol: "DAI",
      decimal: 18,
      address: DAI,
      logo: DaiIcon,
      minDeposit: 15,
    },
    {
      id: 3,
      name: "Test Token USD Coin",
      symbol: "USDC",
      decimal: 6,
      address: USDC,
      logo: USDCIcon,
      minDeposit: 15,
    },
    {
      id: 4,
      name: "Wrapped ETH Test Token",
      symbol: "WETH",
      decimal: 18,
      address: WETH,
      logo: WethIcon,
      minDeposit: 0.005,
    },
    {
      id: 5,
      name: "Credit Token",
      symbol: "CST",
      decimal: 6,
      address: CST,
      logo: WethIcon,
      minDeposit: 15,
    },
  ],
  mainnet: [],
};

export const networkType =
  (process.env.NEXT_PUBLIC_NETWORK_TYPE as string) || "testnet";

export enum Tier {
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
}

let tiers = [
  Tier.One,
  Tier.Two,
  Tier.Three,
  Tier.Four,
  Tier.Five,
  Tier.Six,
  Tier.Seven,
];

export const validTiers: { [x: string]: Tier[] } = {
  secured1: [Tier.One],
  secured2: [Tier.Two],
  secured3: [Tier.Three],
  community1: [Tier.Four],
  community2: [Tier.Five],
  community3: [Tier.Six],
  community4: [Tier.Seven],
  secured: [Tier.One, Tier.Two, Tier.Three],
  community: [Tier.Four, Tier.Five, Tier.Six, Tier.Seven],
  "community-default": [
    Tier.One,
    Tier.Two,
    Tier.Three,
    Tier.Four,
    Tier.Five,
    Tier.Six,
    Tier.Seven,
  ],
};

export const LEASES_PAGE_SIZE = 10;
export const MINIMUM_TOPUP_AMOUNT: Record<string, number> = {
  [USDT]: 15,
  [USDC]: 15,
  [DAI]: 15,
  [WETH]: 0.005,
};
export const MINIMUM_WITHDRAW_AMOUNT = 0;

export const ORDER_UPDATE_ACCEPTED_TIMEOUT = 60000 * 2;

export const ORDER_CREATED_TIMEOUT = 60000;

export const TOKEN_EXPIRY_TIME = 60 * 60 * 6 * 1000;

export const THIRD_WEB_CLIENT_ID =
  process.env.THIRD_WEB_CLIENT_ID || "11fb2288161d8c2fb74666e399b9ac58";

export const MARKETPLACE_MAX_CPU = 24;
export const MARKETPLACE_MAX_CPU_PER_GPU = 8;
export const MARKETPLACE_MAX_STORAGE = 500;
export const MARKETPLACE_MAX_RAM = 36;
export const MARKETPLACE_MAX_RAM_PER_GPU = 32;
export const DISABLE_DEPLOYMENT_AND_DEPOSIT =
  process.env.NEXT_PUBLIC_DISABLE_DEPLOYMENT_AND_DEPOSIT === "true";
